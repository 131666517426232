<div ngForm #dateOfLossForm="ngForm" class="main-inner-wrapper">
    <p style="width:100vw;padding-left:16px;padding-right:16px;">Please provide the date and time of the incident.</p>

    <div class="input-wrapper">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [class.invalid]="DateOfLoss.invalid && (!DateOfLoss.untouched && !DateOfLoss.pristine)">Date Of Loss</label><i [class.invalid]="DateOfLoss.invalid && (!DateOfLoss.untouched && !DateOfLoss.pristine)" class="fas" title="*Required Field"></i>
                </div>
                <mat-form-field floatLabel="never">
                    <input matInput
                           required
                           placeholder="mm/dd/yyyy"
                           (click)="picker.open()"
                           [matDatepicker]="picker"
                           OnlyNumbers
                           DatePattern
                           [max]="maxDate"
                           [min]="minDate"
                           (dateChange)="checkFormValid(dateOfLossForm)"
                           #DateOfLoss="ngModel"
                           name="DateOfLoss"
                           [(ngModel)]="this.incidentDate"
                           [class.invalid]="DateOfLoss.invalid && (!DateOfLoss.untouched && !DateOfLoss.pristine)">

                    <mat-datepicker-toggle disabled matSuffix [for]="picker" class="datePicker"><mat-icon matDatepickerToggleIcon="null"></mat-icon></mat-datepicker-toggle>
                    <mat-datepicker disabled="false" #picker></mat-datepicker>
                </mat-form-field>
                <div class="invalidMessage" [class.d-none]="DateOfLoss.valid || (DateOfLoss.pristine || DateOfLoss.untouched)"><i class="fas fa-exclamation-circle"></i>Please enter a valid date</div>
            </div>

            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(IncidentHour) || childHasInvalid(IncidentMinute) || childHasInvalid(AMPM)">Time Of Loss</label><i [ngClass]="childHasInvalid(IncidentHour) || childHasInvalid(IncidentMinute)" class="fas" title="*Required Field"></i>
                </div>
                <div class="input-group">
                    <input #inputHour (blur)="onHourBlur()" Validate required (keyup)="(inputHour.value.length == 2) ? inputMinute.focus() : ''" type="text" maxlength="2" class="col-3" name="IncidentHour" placeholder="hh" mask="00" [dropSpecialCharacters]="false" [(ngModel)]="this.incidentHour" #IncidentHour="ngModel" />
                    <input #inputMinute Validate required (keyup)="(inputMinute.value.length == 2) ? inputAMPM.focus() : ''" type="text" maxlength="2" class="col-3" name="IncidentMinute" placeholder="mm" mask="00" [dropSpecialCharacters]="false" [(ngModel)]="this.incidentMinute" #IncidentMinute="ngModel" />
                    <div class="input-group-append">
                        <select #inputAMPM Validate class="custom-select" [(ngModel)]="incidentAMPM" name="AMPM" #AMPM="ngModel" required>
                            <option [ngValue]="null">AM/PM</option>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                        </select>
                    </div>

                    <div class="invalidMessage" [class.d-none]="(IncidentHour.valid || IncidentHour.untouched) && (IncidentMinute.valid || IncidentMinute.untouched) && (AMPM.valid || AMPM.untouched)">

                        <i class="fas fa-exclamation-circle"></i>
                        <div *ngIf="IncidentHour.errors?.futureTime then futureMessage else generalMessage"></div>

                        <ng-template #futureMessage>Time entered cannot be in the future</ng-template>
                        <ng-template #generalMessage>Please enter a valid Time</ng-template>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(TimeZone)">Time Zone</label><i [ngClass]="childHasInvalid(TimeZone)" class="fas" title="*Required Field"></i>
                </div>
                <select Validate [(ngModel)]="model.TimeZone" name="timezone" #TimeZone="ngModel" required>
                    <option [ngValue]="null">Select Time Zone</option>
                    <option *ngFor="let tz of model.TimeZones" [ngValue]="tz.DisplayCode">{{ tz.DisplayValue }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(dateOfLossForm)">Continue</button>
    </div>
</div>