import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel } from "@angular/forms";

import { LayoutService, ErrorHandlerService, SessionService } from '@Common/services';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { MyClaimsApi, DashboardService } from '@MyClaims360/services';
import { ClaimModel, ExposureModel, PaymentModel, AddressModel, SessionModel, DocumentModel } from '@ClaimsModels/index';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

@Component({
    selector: 'claimDetail',
    templateUrl: '../views/claimDetail.component.html',
    styleUrls: ['../styles/dashboard.component.css']
})
export class ClaimDetailComponent extends BaseFormComponent {

    public vm: ClaimModel;
    private session: SessionModel;

    constructor(private location: Location, private route: ActivatedRoute, private layoutService: LayoutService,
        private myClaimsApi: MyClaimsApi, private router: Router, private sessionService: SessionService,
        private spinner: Ng4LoadingSpinnerService, private dashboardService: DashboardService) {
        super();
    }

    public ngOnInit(): void {
        this.session = this.sessionService.getSession();

        let dashboardSessionGuid = this.route.snapshot.paramMap.get("sessionId");

        if (this.session != null && this.session.IsInitialized) {
            this.initializeModel();

            this.spinner.show();
            this.myClaimsApi.getClaimDetails(dashboardSessionGuid).then((response) => {
                this.vm = response;

                this.spinner.hide();
            });
        } else {
            this.session.IsExpired = false;
            this.session.IsInitialized = false;
            this.sessionService.setSession(this.session);

            this.initializeModel();

            this.spinner.show();
            this.vm.DashboardSessionGuid = dashboardSessionGuid;

            this.myClaimsApi.initiateDashboard(this.vm).then((response: ClaimModel) => {

                this.dashboardService.setLossParticipantId(response.LossParticipantId.toString());

                response.Session.IsInitialized = true;
                this.sessionService.setSession(response.Session);
                this.vm = response;

                this.spinner.hide();
            });
        }
    }

    public goingBackWithLocation(): void {
        this.location.back();
    }

    protected submit(form: NgForm): void {

    }

    public viewMessages(): void {
        this.router.navigate(['myClaims360/messages']);
    }

    public viewDocuments(lossParticipantId: number): void {
        this.router.navigate(['myClaims360/documents/' + lossParticipantId]);
    }

    public viewPayments(lossParticipantId: number): void {
        this.router.navigate(['myClaims360/payments/' + lossParticipantId]);
    }
    public uploadDocument(lossParticipantId: number): void {
        this.router.navigate(['myClaims360/documentUpload/' + lossParticipantId]);
    }

    public viewDocumentDetails(documentId: number): void {
        this.router.navigate(['myClaims360/documentDetails/' + documentId]);
    }

    private initializeModel(): void {
        this.vm = new ClaimModel();
        this.vm.LossAddress = new AddressModel();
        this.vm.Exposures = [];
        this.vm.LossParticipants = [];
        this.vm.RecentDocuments = [];
    }
}