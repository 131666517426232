import { Component, Input } from '@angular/core';
import { BaseComponent } from '@Common/base/base.component';
import { ConstantsService, LayoutService } from '@Common/services';
import { ClaimStatusBarItemModel } from '../../../ClaimsWeb.Areas.Models/models';

@Component({
    selector: 'claimStatusBar',
    templateUrl: '../views/claimStatusBar.component.html',
    styleUrls: ['../styles/dashboard.component.css']
})

export class ClaimStatusBarComponent extends BaseComponent {
    constructor(private constantsService: ConstantsService, private layoutService: LayoutService) {
        super();
    }

    public statusBarItems: ClaimStatusBarItemModel[];
    @Input() claimStatus: string;

    ngOnInit() {    
        //temp
        this.layoutService.setBrand('nationalgeneral');
    }

    ngOnChanges() {
        this.initializeStatusBar(this.claimStatus);
    }

    public initializeStatusBar(claimStatus: string): void {
        if (claimStatus){
            claimStatus = claimStatus.toUpperCase();
        }
        this.statusBarItems = [
            {
                ClaimStatusDisplay: this.constantsService.ClaimStatusInitial,
                PercentDone: claimStatus == this.constantsService.ClaimStatusOpen || claimStatus == this.constantsService.ClaimStatusClosed ? 100 : 30
            },
            {
                ClaimStatusDisplay: this.constantsService.ClaimStatusOpen,
                PercentDone: claimStatus == this.constantsService.ClaimStatusClosed ? 100 : claimStatus == this.constantsService.ClaimStatusOpen ? 30 : 0
            },
            {
                ClaimStatusDisplay: this.constantsService.ClaimStatusClosed,
                PercentDone: claimStatus == this.constantsService.ClaimStatusClosed ? 100 : 0
            }
        ];
    }
}