import { Component, OnInit, HostListener } from '@angular/core';
import { LayoutService, StyleManagerService, LoggerApi, UnsubmittedClaimService } from '../services';
import { SessionService } from '@Common/services';
import { DOCUMENT } from '@angular/common';
import { Renderer2, Inject } from '@angular/core';
import { RecordingSessionModel } from '../../../ClaimsWeb.Areas.Models/models/RecordingSessionModel';

@Component({
    selector: 'layout',
    templateUrl: '../views/layout.component.html'
})
export class LayoutComponent implements OnInit {
    public year: number;
    public username: string;
    public showUsername: boolean = false;
    private recordingScriptSettings: RecordingSessionModel;

    constructor(private service: LayoutService, private styleManager: StyleManagerService, private loggerApi: LoggerApi,
        private unsubmittedClaim: UnsubmittedClaimService, private sessionService: SessionService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2) { }

    ngOnInit(): void {
        this.recordingScriptSettings = this.sessionService.getRecordingSessionSettings();
        this.year = new Date().getFullYear();
        this.service.getShowUsername().subscribe((show) => {
            this.username = this.service.getUsername();
            this.showUsername = show && (this.username !== null && this.username !== undefined && this.username !== '');
        });

        this.service.getBrand().subscribe(brand => {
            this.styleManager.applyBrand(brand);
        });

        if (this.service.getUsername() !== null &&
            this.service.getUsername() !== undefined &&
            this.service.getUsername() !== '') {
            this.username = this.service.getUsername();
            this.showUsername = true;
        } else {
            this.username = null;
            this.showUsername = false;
        }

        if (this.recordingScriptSettings?.RecordSession == true) {
            let script = this.renderer.createElement('script');
            script.type = 'text/javascript';
            script.src = this.recordingScriptSettings.ScriptSource;
            script.setAttribute(this.recordingScriptSettings.AttributeName, this.recordingScriptSettings.AttributeValue);
            this.renderer.appendChild(this.document.body, script);
        }
    }

    @HostListener("window:beforeunload", ["$event"]) OnPageUnload(event: Event) {
        return this.unsubmittedClaim.displayDefaultBrowserAlert()
    }
}