import { Component } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { BaseFormComponent } from '@Common/base/baseForm.component';

import { LayoutService, SessionService, SessionBridgeApi, LoggerApi, UserLoginApi } from '@Common/services';
import { DashboardSettings, UserLoginModel } from '@ClaimsModels/index';
import { FNOLAutoService } from '../../../../ClaimsWeb.Areas.FNOL.Auto/app/services';

@Component({
    selector: 'dashboard',
    templateUrl: '../../views/fnol/fnolDashboard.component.html'
})
export class FNOLDashboardComponent extends BaseFormComponent {
    public showBrand: boolean = true;

    public isAuthenticated: boolean = false;
    public user: UserLoginModel = new UserLoginModel();
    public hasError: boolean = null;

    public _policyNumber: string = null;

    private isEncompassAgencyDashboard: boolean = false;

    protected submit(form: NgForm): void { }

    constructor(private router: Router, private bridgeApi: SessionBridgeApi,
        private layoutService: LayoutService, private session: SessionService, private loggerApi: LoggerApi,
        private loginApi: UserLoginApi,
        private spinner: Ng4LoadingSpinnerService) {
        super();
    }

    ngOnInit(): void {
        this.spinner.show();

        let useEncompass: boolean = false;
        let url = window.location.host.split('.');

        if (url.length > 1) {
            if (url[1] === 'myclaim360') {
                this.isEncompassAgencyDashboard = true;
                useEncompass = true;
            }
        }

        this.loggerApi.getDashboardSettings().then((settings: DashboardSettings[]) => {
            if (settings.find(x => x.Key === "FNOLDashboardEnabled").Value === "false") {
                this.spinner.hide();
                this.router.navigate(['404']);
                return;
            }

            if (settings.find(x => x.Key === "BrandEnabled").Value === "false") {
                this.showBrand = false;
            }

            this.layoutService.clearUsername();
            this.layoutService.setShowUsername(false);
            this.session.clearSession();

            this.bridgeApi.getBrand(useEncompass).then(response => {
                this.layoutService.setBrand(response);

                this.spinner.hide();
            });
        });
    }

    public signIn(form: NgForm): void {

        // if the form is invalid, exit
        if (form && form.invalid) {
            this.hasError = true;
            return;
        }

        this.user.Password = btoa(this.user.Password);

        this.spinner.show();
        this.loginApi.validateUserLoginModel(this.user).then(response => {
            this.isAuthenticated = response.IsAuthenticated;
            this.hasError = !response.IsAuthenticated;
            this.spinner.hide();
        });
    }

    public submitForm(form: HTMLFormElement): void {
        let policyNumber: HTMLInputElement = <HTMLInputElement>form.children.namedItem("PolicyInput");
        let userType: HTMLInputElement = <HTMLInputElement>form.children.namedItem("userType");

        if (policyNumber.value == "") {
            policyNumber = <HTMLInputElement>form.children.namedItem("PolicyNumber");
        }
        policyNumber.value = policyNumber.value.replace(/\s/g, "");

        let appName = this.getApplicationName(form, this.isEncompassAgencyDashboard);
        let sourceParty = <HTMLInputElement>form.children.namedItem("sourceParty");

        this.bridgeApi.generateBridgePayload(policyNumber.value, appName.value, sourceParty.value)
            .then(response => {
                let authToken: HTMLInputElement = <HTMLInputElement>form.children.namedItem("Token");
                authToken.value = response;

                // I am blanking this out to show that in the ReceiveJWT call, we require the token to provide this value.
                // The PolicyNumber is required for token.
                policyNumber.value = "";

                form.submit();
            });
    }

    public goingBackWithLocation(): void {

    }
}