<div class="content-wrapper">
    <div class="payment-box">
        <div class="box-header">
            <h2 class="col-10">Claim # {{vm.ClaimNumber}}</h2>
            <button class="button-arrow" (click)="goingBackWithLocation()">Back to Claim <img src="/content/images/arrow.svg" width="20" height="25" /></button>
        </div>
        <div class="details-row">
            <div class="payment-card">
                <h4 class="col-10">Payments</h4>
                <div *ngFor="let payment of vm.Payments" style="margin:10px;padding:10px;box-shadow:0 0 3px;border-radius:10px;">
                    <span>Paid To {{payment.PaidTo}}</span><br />
                    <span>Amount {{payment.Amount | currency}}</span><br />
                    <span>Check {{payment.CheckNumber}} issued on {{payment.ActualPaidDate | date}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
