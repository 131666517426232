import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel } from "@angular/forms";
import { LayoutService, ErrorHandlerService, SessionService } from '@Common/services';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { MyClaimsApi } from '@MyClaims360/services';
import { DocumentModel } from '../../../ClaimsWeb.Areas.Models/models';
import { DomSanitizer } from '@angular/platform-browser';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

@Component({
    selector: 'documentDetails',
    templateUrl: '../views/documentDetails.component.html',
    styleUrls: ['../styles/dashboard.component.css']
})

export class DocumentDetailsComponent extends BaseFormComponent {
    public vm: DocumentModel;
    public claimDocuments: DocumentModel[];
    public fileContents: Blob;
    public fileURL: string;
    public previewURL: any;

    @ViewChild('docPreviewCanvas', { static: true })
    docPreviewCanvas: ElementRef<HTMLCanvasElement>;

    public ngOnInit(): void {
        let documentId: number = +this.route.snapshot.paramMap.get("documentId");

        this.spinner.show();
        this.myClaimsApi.getDocumentDetails(documentId).then((response) => {
            this.vm = response;            

            let base64String: string = this.vm.DocumentContent.toString();
            this.fileContents = new Blob([this.base64ToArrayBuffer(base64String)], { type: this.vm.DocumentType });
            this.fileURL = URL.createObjectURL(this.fileContents);

            this.previewURL = 'data:' + this.vm.DocumentType + ';base64, ' + base64String;

            if (this.vm.DocumentType.indexOf('image') > -1) {
                window.document.getElementById("docPreview").innerHTML = "<img src='" + this.previewURL + "' />";
            }
            else if (this.vm.DocumentType.indexOf('pdf') > -1) {
                window.document.getElementById("docPreview").innerHTML = "<iframe src='" + this.previewURL + "' type='" + this.vm.DocumentType + "' frameborder='0' width='100%' height='100%' style='border: 0; top: 0px; left: 0px; bottom: 0px; right: 0px;width:100%; height:100%;' allowfullscreen></iframe>";
            }
            else {
                window.document.getElementById("docPreview").innerHTML = "<span>This Document Cannot Be Previewed At This Time.  Download Your Document Below.</span>";
            }

            this.myClaimsApi.getLossParticipantDocuments(this.vm.LossParticipantId).then(docResponse => {
                this.claimDocuments = docResponse.Documents;

                this.spinner.hide();
            });
        });
    }
    public goingBackWithLocation(): void {
        this.location.back();
    }
    protected submit(form: NgForm): void {
        
    }
    constructor(private location: Location, private route: ActivatedRoute, private layoutService: LayoutService, private myClaimsApi: MyClaimsApi, private router: Router,
        private sessionService: SessionService, private sanitizer: DomSanitizer, private spinner: Ng4LoadingSpinnerService) {
        super();
    }
    public viewDocument(): void {
        window.open(this.fileURL);
    }

    private base64ToArrayBuffer(base64: string) {
        const binaryString: string = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    public viewDocuments(): void {
        this.router.navigate(['myClaims360/documents/' + this.vm.LossParticipantId]);
    }
    public viewDocumentDetails(newDocumentId: number): void {
        this.spinner.show();
        this.myClaimsApi.getDocumentDetails(newDocumentId).then((response) => {
            this.vm = response;
            let base64String: string = response.DocumentContent.toString();
            let fileContents = new Blob([this.base64ToArrayBuffer(base64String)], { type: response.DocumentType });
            this.fileURL = URL.createObjectURL(fileContents);
            this.previewURL = 'data:' + response.DocumentType + ';base64,' + base64String;

            if (this.vm.DocumentType.indexOf('image') > -1) {
                window.document.getElementById("docPreview").innerHTML = "<img src='" + this.previewURL + "' />";
            }
            else if (this.vm.DocumentType.indexOf('pdf') > -1) {
                window.document.getElementById("docPreview").innerHTML = "<iframe src='" + this.previewURL + "' type='" + this.vm.DocumentType + "' frameborder='0' width='100%' height='100%' style='border: 0; top: 0px; left: 0px; bottom: 0px; right: 0px;width:100%; height:100%;' allowfullscreen></iframe>";
            }
            else {
                window.document.getElementById("docPreview").innerHTML = "<span>This Document Cannot Be Previewed At This Time.  Download Your Document Below.</span>";
            }
            this.spinner.hide();
        });
    }
}