<header>
    <nav>
        <div class="brand">
            <img class="img-fluid logo" />
        </div>
        <i *ngIf="showUsername" class="fas fa-user-circle"></i>
        <span *ngIf="showUsername" class="userName">{{username}}</span>
    </nav>
</header>
<div>
    <ng4-loading-spinner [threshold]="250" [timeout]="3000000"> </ng4-loading-spinner>
    <breadcrumb></breadcrumb>
    <error></error>
    <main id="main">
        <router-outlet>
        </router-outlet>
    </main>
</div>
<footer>
    &copy;{{ year }}
</footer>