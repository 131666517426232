import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel } from "@angular/forms";
import { LayoutService, ErrorHandlerService, SessionService } from '@Common/services';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { MyClaimsApi } from '@MyClaims360/services';
import { Location } from '@angular/common';
import { ClaimPaymentsModel } from '@ClaimsModels/index';

@Component({
    selector: 'payments',
    templateUrl: '../views/payments.component.html',
    styleUrls: ['../styles/dashboard.component.css']
})
export class PaymentsComponent extends BaseFormComponent {
    public ngOnInit(): void {

    }
    public goingBackWithLocation(): void {
        this.location.back();
    }
    protected submit(form: NgForm): void {

    }
    public vm: ClaimPaymentsModel;
    private lossParticipantId: number;

    constructor(private location: Location, private route: ActivatedRoute, private layoutService: LayoutService, private myClaimsApi: MyClaimsApi, private router: Router, private sessionService: SessionService) {
        super();

        this.lossParticipantId = +this.route.snapshot.paramMap.get("lossParticipantId");

        this.myClaimsApi.getLossParticipantPayments(this.lossParticipantId).then((response) => {
            this.vm = response;
        });
    }
}