




import { BaseModel } from './BaseModel';

import { ExposureModel } from './ExposureModel';
import { AddressModel } from './AddressModel';
import { BaseLossParticipantModel } from './BaseLossParticipantModel';
import { DocumentModel } from './DocumentModel';
import { PaymentModel } from './PaymentModel';
import { SessionModel } from './SessionModel';

export class ClaimModel extends BaseModel {
    
    public ClaimNote: string = null;
    public LossTimeZone: string = null;
    public LossDateTime: Date = null;
    public ClaimNumber: string = null;
    public ClaimType: string = null;
    public LossDescription: string = null;
    public PropertyDescription: string = null;
    public Exposures: ExposureModel[] = [];
    public LossAddress: AddressModel = null;
    public LossParticipants: BaseLossParticipantModel[] = [];
    public RecentDocuments: DocumentModel[] = [];
    public Payments: PaymentModel[] = [];
    public ClaimStatus: string = null;
    public CloseDateTime: Date = null;
    public Session: SessionModel = null;
    public PropertyDriverName: string = null;
    public LossParticipantId: number = 0;
    public DashboardSessionGuid: string = null;
}