<div class="content-wrapper">
    <div class="overview-box">
        <div class="box-header">
            <h2>Claim Overview</h2>
        </div>
        <div class="details-row">
            <div id="claimsHeader" class="claim-card details-info">
                <div style="display:flex; justify-content:space-between;">
                    <h4 class="col-6">Claim # {{vm.ClaimNumber}}</h4>
                    <claimStatusBar [hidden]="!this.vm.ClaimStatus" [claimStatus]="this.vm.ClaimStatus"></claimStatusBar>
                </div>
                <div class="claim-info">
                    <div class="avatar-lg">
                        <img src="/content/images/vehicle-icon-filled.svg" width="45" height="45" />
                    </div>
                    <div class="claim-description">
                        <h4 class="property">{{vm.PropertyDescription}}</h4>
                        <span class="incident-date">Incident Date {{vm.LossDateTime | date}}</span>
                    </div>
                </div>
                <div class="claim-details">
                    <div *ngIf="vm.PropertyDriverName != ''" class="col-6">
                        <h6>Driver</h6>
                        {{vm.PropertyDriverName}}
                    </div>
                    <div class="claim-status col-6">
                        <h6>Address</h6>
                        <span>{{vm.LossAddress.Address1}}</span><br />
                        <span *ngIf="vm.LossAddress.Address2!=null && vm.LossAddress.Address2!=''">{{vm.LossAddress.Address2}}<br /></span>
                        <span>{{vm.LossAddress.City}}, {{vm.LossAddress.State}}, {{vm.LossAddress.Zip}}</span>
                    </div>
                </div>
                <div class="claim-details">
                    <div class="col-10">
                        <h6>Details</h6>
                        {{vm.LossDescription}}
                    </div>
                </div>
            </div>
        </div>

        <div class="details-row">
            <div class="claim-card details-pay">
                <div class="details-header">
                    <h4 class="col-9">Payments</h4>
                    <button class="button-arrow" (click)="viewPayments(vm.LossParticipantId)">View All <img src="/content/images/arrow.svg" width="20" height="25" /></button>
                </div>
                <div>
                    <div *ngFor="let payment of vm.Payments">
                        <div style="width:60%;float:left;">
                            {{payment.ActualPaidDate}}<br />
                            {{payment.PaidTo}}
                        </div>
                        <div style="width:40%;float:right;">{{payment.Amount | currency}}</div>
                    </div>
                </div>
            </div>

            <div class="claim-card details-contacts">
                <div class="details-header">
                    <h4 class="col-6">Contacts</h4>
                    <button class="button-arrow" (click)="viewMessages()">Message Center <img src="/content/images/arrow.svg" width="20" height="25" /></button>
                </div>
                <div>
                    <div *ngFor="let exposure of vm.Exposures">
                        <div style="width:60%;float:left">
                            {{exposure.AdjusterFirstName}} {{exposure.AdjusterLastName}}<br />
                            {{exposure.AssignedToRole}}
                        </div>
                        <div style="width:40%;float:right">
                            {{exposure.AdjusterEmail}}<br />
                            Send a message
                        </div>
                    </div>
                </div>
                <span class="help-text">Questions? Contact your adjuster here.</span>
            </div>
        </div>

        <div>
            <button (click)="goingBackWithLocation()" class="button button-primary" style="float:right;">Return</button>
        </div>
    </div>

    <div class="document-box">
        <div class="details-header">
            <h2 class="col-9">Documents</h2><br />
            <button class="button-arrow" (click)="viewDocuments(vm.LossParticipantId)">View All <img src="/content/images/arrow.svg" width="20" height="25" /></button>
        </div>
        <span class="help-text">View or upload documents regarding claim.</span>        
        <div *ngFor="let document of vm.RecentDocuments" style="border: 1px solid lightgrey;border-radius:10px;padding:10px;margin-left:15px;">
            <span>{{document.DocumentName}}</span><br />
            <span style="font-size:smaller;">Uploaded {{document.DateUploaded | date}}</span>&nbsp;
            <button (click)="viewDocumentDetails(document.DocumentId)">View</button>
        </div>
        <div class="button-wrapper">
            <button class="button button-primary" (click)="uploadDocument(vm.LossParticipantId)">Upload</button>
        </div>
    </div>
</div>