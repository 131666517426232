import { Injectable } from '@angular/core';
import { SessionService } from '@Common/services/';
import { BaseService } from '@Common/base/base.service';

@Injectable()
export class DashboardService extends BaseService {

    private _lossParticipantId: string = "LossParticipantId";

    constructor(sessionService: SessionService) {
        super(sessionService);
    }

    public setLossParticipantId(value: string): void {
        this.sessionService.set(this._lossParticipantId, value);
    }

    public getLossParticipantId(): string {
        return this.sessionService.get(this._lossParticipantId);
    }
}