import { BrowserModule } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel, FormsModule } from "@angular/forms";
import { LayoutService, ErrorHandlerService, SessionService } from '@Common/services';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { MyClaimsApi } from '@MyClaims360/services';
import { ClaimDocumentsModel, DocumentModel } from '@ClaimsModels/index';

@Component({
    selector: 'documents',
    templateUrl: '../views/documents.component.html',
    styleUrls: ['../styles/dashboard.component.css']
})

export class DocumentsComponent extends BaseFormComponent {
    public vm: ClaimDocumentsModel;
    private lossParticipantId: number;
    constructor(private location: Location, private route: ActivatedRoute, private layoutService: LayoutService, private myClaimsApi: MyClaimsApi, private router: Router, private sessionService: SessionService) {
        super();
    }

    public ngOnInit(): void {
        this.sessionService.refreshSession();
        this.lossParticipantId = +this.route.snapshot.paramMap.get("lossParticipantId");

        this.myClaimsApi.getLossParticipantDocuments(this.lossParticipantId).then(response => {
            this.vm = response;
        });
    }
    public goingBackWithLocation(): void {
        this.location.back();
    }
    public submit(form: NgForm): void {

    }
    public uploadDocument(): void {
        this.router.navigate(['myClaims360/documentUpload/' + this.lossParticipantId]);
    }

    public viewDocumentDetails(document: DocumentModel): void {
        this.router.navigate(['myClaims360/documentDetails/' + document.DocumentId])
        //const base64String: string = document.DocumentContent.toString();
        //const fileContents: Blob = new Blob([this.base64ToArrayBuffer(base64String)], { type: document.DocumentType });
        //let fileURL: string = URL.createObjectURL(fileContents);

        //const previewURL: any = 'data:' + document.DocumentType + ';base64,' + base64String;
        //window.document.write('<iframe src="' + previewURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
        //window.open(previewURL, "_blank");
    }

    private base64ToArrayBuffer(base64: string) {
        const binaryString: string = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }
};