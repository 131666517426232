import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonDirectivesModule } from '../../ClaimsWeb.Areas.Common/app/commonDirectives.module';
import { CommonComponentsModule } from '../../ClaimsWeb.Areas.Common/app/commonComponents.module';


import { NgxMaskModule } from 'ngx-mask';

import { CommonModule } from '@Common/common.module';
import { AuthGuardService } from '@Common/services/';

import {
    CustomerValidationComponent, CustomerValidationErrorComponent, MyClaimsHomeComponent, ClaimDetailComponent,
    VehicleDamageComponent, MessagesComponent, PaymentsComponent, DocumentsComponent, DocumentUploadComponent, DocumentDetailsComponent, ClaimStatusBarComponent
} from './components';
import { MyClaimsApi, DashboardService } from './services';

const myClaims360Routes: Routes = [
    {
        path: 'myClaims360/customerValidation/:id',
        component: CustomerValidationComponent,
        canActivate: [AuthGuardService]
    },
    { path: 'myClaims360/customerValidationError', component: CustomerValidationErrorComponent },
    { path: 'myClaims360/myClaimHome/:sessionId', component: MyClaimsHomeComponent },
    { path: 'myClaims360/claimDetails/:sessionId', component: ClaimDetailComponent },
    { path: 'myClaims360/messages', component: MessagesComponent },
    { path: 'myClaims360/payments/:lossParticipantId', component: PaymentsComponent },
    { path: 'myClaims360/documents/:lossParticipantId', component: DocumentsComponent },
    { path: 'myClaims360/documentUpload/:lossParticipantId', component: DocumentUploadComponent },
    { path: 'myClaims360/documentDetails/:documentId', component: DocumentDetailsComponent }
];

@NgModule({
    declarations: [
        CustomerValidationComponent,
        MyClaimsHomeComponent,
        ClaimDetailComponent,
        VehicleDamageComponent,
        MessagesComponent,
        PaymentsComponent,
        DocumentsComponent,
        ClaimStatusBarComponent,
        DocumentDetailsComponent,
        DocumentUploadComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forChild(myClaims360Routes),
        FormsModule,
        NgxMaskModule.forRoot(),
        CommonDirectivesModule,
        CommonComponentsModule
    ],
    providers: [
        MyClaimsApi, DashboardService
    ]
})
export class MyClaims360Module { }