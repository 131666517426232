import { Component, QueryList, ViewChildren } from '@angular/core';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import * as moment from 'moment';
import { Location } from '@angular/common';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { ReviewModel, LossDetailsModel, AddressModel, EmailModel, PhoneModel, DisplayModeEnum, LossParticipantModel, VehicleInfoModel, PropertyInfoModel, ClaimTypeCategoryEnum, ValueDetailModel } from '@ClaimsModels/index';
import { LossApi, ParticipantApi, VehicleApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { StyleManagerService, ConstantsService } from '@Common/services';
import { ReviewSubmissionBottomSheet } from '@Common/components/modals/reviewSubmission.component'; 
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgModel } from '@angular/forms';


@Component({
    selector: 'review',
    templateUrl: '../../views/loss/review.component.html',
    providers: [FNOLRoutingService]
})
export class ReviewComponent extends BaseFormComponent {

    constructor(private lossApi: LossApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService, private fnolAutoService: FNOLAutoService,
        private participantApi: ParticipantApi, private vehicleApi: VehicleApi, private location: Location, private styleManager: StyleManagerService, private bottomSheet: MatBottomSheet,
        private constantsService: ConstantsService) {
        super();

        this.vm = new ReviewModel();
        this.vm.LossDetails = new LossDetailsModel();
        this.vm.LossParticipants = [];
        this.vm.Vehicles = [];
        this.vm.LossAddress = new AddressModel();
        this.vm.PropertyInfoModels = [];
    }

    public vm: ReviewModel = new ReviewModel();
    public isEditingLossInfo: boolean;
    public lossDate: string = this.constantsService.EmptyString;
    public lossTime: string = this.constantsService.EmptyString;
    public lossCityStateZip = this.constantsService.EmptyString;
    public hasError: boolean = false;
    public contactNumber: string;
    private originalData = {};
    @ViewChildren("Phone") private phoneNumbers: QueryList<NgModel>;
    @ViewChildren("PhoneType") private phoneTypes: QueryList<NgModel>;

    ngOnInit(): void {
        this.spinner.show();
        this.lossApi.getReviewModel().then(response => {
            this.spinner.hide();
            this.vm = response;

            this.vm.AddUnknownDriver = this.getUnknownDriverValue();
            this.vm.AddUnknownOwner = this.getUnknownOwnerValue();

            this.vm.AddUnknownClaimantDriver = this.getUnknownClaimantDriverValue();
            this.vm.AddUnknownClaimantOwner = this.getUnknownClaimantOwnerValue();

            this.vm.AddUnknownPedestrian = this.getUnknownPedestrianValue();
            this.vm.AddUnknownFixedPropertyOwner = this.getUnknownFixedPropertyOwnerValue();

            this.vm.HasClaimantInfo = this.getHasClaimantInfoValue();

            this.vm.DisplayLossDescriptionBox = this.canDisplayLossDescriptionBox();

            this.lossDate = moment(new Date(this.vm.LossDetails.LossDate)).format("MM/DD/YYYY");
            this.lossTime = moment(new Date(this.vm.LossDetails.LossDate)).format("h:mm a");

            this.lossCityStateZip = this.vm.LossAddress.State;
            if (this.haveDataFor(this.vm.LossAddress.City)) {
                this.lossCityStateZip = `${this.vm.LossAddress.City}, ${this.lossCityStateZip}`;
            }

            if (this.haveDataFor(this.vm.LossAddress.Zip)) {
                this.lossCityStateZip += ` ${this.vm.LossAddress.Zip}`;
            }

            this.styleManager.contactPhoneNumber.subscribe(contactPhoneNumber => {
                this.contactNumber = contactPhoneNumber;
            })

            this.vm.LossParticipants.forEach(x => {
                if (x.ContactInfo.Emails === null || x.ContactInfo.Emails.length === 0) {
                    x.ContactInfo.Emails.push(new EmailModel());
                }

                while (x.ContactInfo.Phones === null || x.ContactInfo.Phones.length < 3) {
                    let newPhone = new PhoneModel();
                    newPhone.IsActive = false;
                    x.ContactInfo.Phones.push(newPhone);
                }
            });
        });
    }

    public toggle(model: LossParticipantModel | VehicleInfoModel | PropertyInfoModel) {

        switch (model.DisplayMode) {
            case DisplayModeEnum.ReadOnly:
                model.DisplayMode = DisplayModeEnum.Expanded;
                break;
            case DisplayModeEnum.Edit:
                // add code here to undo edit mode ?
                this.revertChanges(model);
                model.DisplayMode = DisplayModeEnum.ReadOnly;
                break;
            default:
                model.DisplayMode = DisplayModeEnum.ReadOnly;
                break;
        }
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public getVehiceOccupantPositionByVehicle(vehicle: VehicleInfoModel, role: string): string {

        let output: string = this.constantsService.EmptyString;  

        switch (role) {
            case this.constantsService.Owner:
                let owner: LossParticipantModel = this.vm.LossParticipants.find(x => x.LossParticipantId == vehicle.VehicleClaimInfo?.OwnerContactId);

                if (!owner) {
                    output = 'Owner: Unknown Owner';
                    break;
                }

                output = `Owner: ${owner.FirstName} ${owner.LastName}`;
                break;
            case this.constantsService.Driver:
                let driver: LossParticipantModel = this.vm.LossParticipants.find(x => x.LossParticipantId == vehicle.VehicleClaimInfo?.DriverContactId);

                if (!driver) {
                    output = this.hasUnknownDriverOrNoDriverChecker(vehicle);
                    break;
                }

                output = `Driver: ${driver.FirstName} ${driver.LastName}`;
                break;
            case this.constantsService.Passenger:

                if (vehicle.SelectedPassengers.length == 0) {
                    output = 'Passengers: No passengers';
                    break;
                }

                let passengerNames: string = vehicle.SelectedPassengers.map(s => {
                    let person = this.vm.LossParticipants.find(p => p.LossParticipantId == s.Id);

                    return `${person.FirstName} ${person.LastName}`;
                }).join(", "); // split each person by comma

                output = `Passengers: ${passengerNames}`;

                break;
        }

        return output;
    }

    private hasUnknownDriverOrNoDriverChecker(vehicle: VehicleInfoModel): string {
        let hasUnknownDriver: boolean = null;

        if (vehicle.VehicleClaimInfo.IsUnlisted || vehicle.VehicleClaimInfo.IsOnPolicy) {
            hasUnknownDriver = this.vm.AddUnknownDriver
        } else {
            hasUnknownDriver = this.vm.AddUnknownClaimantDriver;
        }

        if (hasUnknownDriver) {
            return "Driver: Unknown Driver";
        } else {
            return "Driver: No Driver";
        }

    }

    public editMode(model: LossParticipantModel | VehicleInfoModel | PropertyInfoModel) {
        model.DisplayMode = DisplayModeEnum.Edit;

        // make a copy of the original data
        this.storeOriginalData(model);
    }

    public updateLossParticipant(participant: LossParticipantModel, ...ngModels): void {
        let phoneNumberModels: NgModel[] = this.phoneNumbers.filter(x => x.name == this.constantsService.ParticipantPhoneNumber + participant.LossParticipantId);
        let phoneTypeModels: NgModel[] = this.phoneTypes.filter(x => x.name == this.constantsService.ParticipantPhoneType + participant.LossParticipantId)

        if (phoneNumberModels != null) {
            phoneNumberModels.forEach(phoneNumber => ngModels.push(phoneNumber));
        }

        if (phoneTypeModels != null) {
            phoneTypeModels.forEach(phoneType => ngModels.push(phoneType));
        }

        if (this.hasInvalidModels(ngModels)) {
            return;
        }


        if (participant.FirstName.trim() != this.constantsService.EmptyString && participant.LastName.trim() != this.constantsService.EmptyString) {
            this.spinner.show();
            this.participantApi.saveLossParticipantModel(participant).then(response => {
                this.spinner.hide();
                participant.DisplayMode = DisplayModeEnum.Expanded;

                // remove our saved data
                this.cleanupOriginalData(participant);
            });
        }
        else {
            participant = this.originalData[participant.LossParticipantId];
        }
    }

    public updateVehicle(vehicle: VehicleInfoModel, ...ngModels): void {

        if (this.hasInvalidModels(ngModels)) {
            return;
        }

        this.spinner.show();

        this.vehicleApi.saveVehicleInfo(vehicle).then(response => {
            this.spinner.hide();
            vehicle.DisplayMode = DisplayModeEnum.Expanded;

            // remove our saved data
            this.cleanupOriginalData(vehicle);
        });
    }

    public updateProperty(property: PropertyInfoModel, ...ngModels): void {
        if (this.hasInvalidModels(ngModels)) {
            return;
        }

        this.spinner.show();

        this.lossApi.savePropertyInfo(property).then(response => {
            this.spinner.hide();

            property.DisplayMode = DisplayModeEnum.Expanded;

            this.cleanupOriginalData(property);

        });
    }

    public transposeVehicleLocationCode(model: VehicleInfoModel): string {
        return model.VehicleLocationTypes.find(x => x.DisplayCode == model.VehicleClaimInfo.VehicleLocationType).DisplayValue;
    }

    public expandAll(models: LossParticipantModel[] | VehicleInfoModel[]): void {
        models.forEach((model: LossParticipantModel | VehicleInfoModel) => { model.DisplayMode = DisplayModeEnum.Expanded });

        this.expandFixedProperty(models);
    }

    public closeAll(models: LossParticipantModel[] | VehicleInfoModel[]): void {
        // could add code in here to say if DisplayMode is Edit, revert UI changes back to original changes ?
        models.forEach((model: LossParticipantModel | VehicleInfoModel) => {

            if (model.DisplayMode == DisplayModeEnum.Edit) {
                // apply revert logic
                this.revertChanges(model);
            }

            model.DisplayMode = DisplayModeEnum.ReadOnly
        });

        this.closeFixedProperty(models);
    }

    public showCloseAll(models: LossParticipantModel[] | VehicleInfoModel[]): boolean {
        let totalExpanded: number = 0;
        let totalEdit: number = 0;

        // array.filter did not like the different types I use for an unknown reason
        models.forEach((model: LossParticipantModel | VehicleInfoModel) => {
            switch (model.DisplayMode) {
                case DisplayModeEnum.Expanded:
                    totalExpanded++;
                    break;
                case DisplayModeEnum.Edit:
                    totalEdit++;
                    break;
            }
        });
        
        if (totalEdit == models.length) {
            return true;
        } else if (totalExpanded == models.length) {
            return true;
        } else if (totalEdit + totalExpanded == models.length) {
            return true;
        }

        return false;
    }

    public updateLossInfo(...ngModels): void {

        if (this.hasInvalidModels(ngModels)) {
            return;
        }
        this.spinner.show();

        this.lossApi.saveLossDetailsModel(this.vm.LossDetails).then(response => {
            this.spinner.hide();
            this.isEditingLossInfo = false;
        });
    }

    public getParticipantDisplayAddress(participant: LossParticipantModel): string {

        if (participant !== null && participant !== undefined && participant.Address !== null
            && !this.IsNullOrEmptyString(participant.Address.City) && !this.IsNullOrEmptyString(participant.Address.State)
            && !this.IsNullOrEmptyString(participant.Address.Zip)) {
            return `${participant.Address.City}, ${participant.Address.State} ${participant.Address.Zip}`;
        }

        return this.constantsService.EmptyString;
    }

    public availablePhoneTypes(participant: LossParticipantModel, phone: PhoneModel): ValueDetailModel[] {
        let activePhones = participant.ContactInfo.Phones.filter(x => x.IsActive);

        let result = [];

        this.vm.PhoneTypes.forEach(x => {
            if (!activePhones.find(y => y.PhoneType === x.DisplayCode) || phone.PhoneType === x.DisplayCode) {
                result.push(x);
            }
        });

        return result;
    }

    public removePhone(phone: PhoneModel): void {
        phone.IsActive = false;
    }

    public canDisplayRemoveButton(participant: LossParticipantModel): boolean {
        return participant.ContactInfo.Phones.filter(phone => {
            return phone.IsActive
        }).length > 1
    }

    public canAddPhone(participant: LossParticipantModel): boolean {
        if (participant.ContactInfo === null || participant.ContactInfo.Phones === null) {
            return false;
        }

        let activePhones = participant.ContactInfo.Phones.filter(x => x.IsActive);

        return activePhones.length < 3;
    }

    public addPhone(participant: LossParticipantModel): void {
        for (let i = 0; i < participant.ContactInfo.Phones.length; i++) {
            if (!participant.ContactInfo.Phones[i].IsActive) {
                participant.ContactInfo.Phones[i].IsActive = true;
                break;
            }
        }
    }

    public editLossInfo() {
        this.isEditingLossInfo = true;
    }

    public submit(): void {
        var currentSheet: MatBottomSheetRef = this.bottomSheet.open(ReviewSubmissionBottomSheet);
        currentSheet.afterDismissed().subscribe(result => {
            if (result?.event == this.constantsService.Submit) {
                this.spinner.show();
                this.lossApi.submitReviewModel(this.vm).then((response) => {
                    this.spinner.hide();
                    if (response.Success && response.IsSuccessfulSubmit) {
                        this.fnolRouting.next();
                    } else {
                        this.hasError = true;
                    }
                });
            }
        });
    }

    private isVehicleInfoKnown(vehicle: VehicleInfoModel): boolean {
        return (vehicle.Year != undefined && vehicle.Year.trim() != this.constantsService.EmptyString) ||
            (vehicle.Make != undefined && vehicle.Make.trim() != this.constantsService.EmptyString) ||
            (vehicle.Model != undefined && vehicle.Model.trim() != this.constantsService.EmptyString);
    }

    private isPropertyInfoKnown(property: PropertyInfoModel): boolean {
        return (property.PropertyDescription != undefined && property.PropertyDescription.trim() != this.constantsService.EmptyString);
    }

    private storeOriginalData(model: LossParticipantModel | VehicleInfoModel | PropertyInfoModel): void {
        // create a copy of the object.  Otherwise, databinding still occurs against the copy.

        if ('PropertyClaimId' in model) {
            this.originalData[model.PropertyClaimId] = Object.assign({}, model);

        } else if ('LossParticipantId' in model) {
            this.originalData[model.LossParticipantId] = Object.assign({}, model);

        } else if ('PropertyId' in model) {

            if ('PropertyClaimId' in model) {
                return;
            }

            this.originalData[model.PropertyId] = Object.assign({}, model);
        }
    }

    private revertChanges(model: LossParticipantModel | VehicleInfoModel | PropertyInfoModel): void {

        if ('PropertyClaimId' in model) {
            let propertyIndex = this.vm.PropertyInfoModels.findIndex(x => x.PropertyClaimId === model.PropertyClaimId);
            let originalModel = this.originalData[model.PropertyClaimId];

            for (let column in originalModel) {
                this.vm.PropertyInfoModels[propertyIndex][column] = originalModel[column];
            }

        } else if ('LossParticipantId' in model) {
            let lossParticipantIndex = this.vm.LossParticipants.findIndex(x => x.LossParticipantId === model.LossParticipantId);
            let originalModel = this.originalData[model.LossParticipantId];

            for (let column in originalModel) {
                this.vm.LossParticipants[lossParticipantIndex][column] = originalModel[column];
            }

        } else if ('PropertyId' in model) {
            let vehicleIndex = this.vm.Vehicles.findIndex(x => x.PropertyId === model.PropertyId);
            let originalModel = this.originalData[model.PropertyId];

            for (let column in originalModel) {
                this.vm.Vehicles[vehicleIndex][column] = originalModel[column];
            }

        }

        this.cleanupOriginalData(model);
    }

    private cleanupOriginalData(model: LossParticipantModel | VehicleInfoModel | PropertyInfoModel): void {

        if ('PropertyClaimId' in model) {
            delete this.originalData[model.PropertyClaimId];

        } else if ('LossParticipantId' in model) {
            delete this.originalData[model.LossParticipantId];

        } else if ('PropertyId' in model) {
            delete this.originalData[model.PropertyId];

        }
        
    }

    private getUnknownDriverValue(): boolean {
        let unknownDriver: string = this.fnolAutoService.getUnknownDriver();

        return this.unknownPartyStringValueToBoolen(unknownDriver);
    }

    private getUnknownOwnerValue(): boolean {
        let unknownOwner: string = this.fnolAutoService.getUnknownOwner();

        return this.unknownPartyStringValueToBoolen(unknownOwner);
    }

    private getUnknownClaimantDriverValue(): boolean {
        let unknownClaimantDriver: string = this.fnolAutoService.getUnknownClaimantDriver();

        return this.unknownPartyStringValueToBoolen(unknownClaimantDriver);
    }

    private getUnknownClaimantOwnerValue(): boolean {
        let unknownClaimantOwner: string = this.fnolAutoService.getUnknownClaimantOwner();

        return this.unknownPartyStringValueToBoolen(unknownClaimantOwner);
    }

    private getUnknownPedestrianValue(): boolean {
        let unknownPedestrian: string = this.fnolAutoService.getUnknownPedestrian();

        return this.unknownPartyStringValueToBoolen(unknownPedestrian);
    }

    private getHasClaimantInfoValue(): boolean {
        let hasClaimantInfo = this.fnolAutoService.getHasClaimantInfo();

        return this.unknownPartyStringValueToBoolen(hasClaimantInfo);
    }

    private getUnknownFixedPropertyOwnerValue(): boolean {
        let unknownOwner = this.fnolAutoService.getUnknownFixedPropertyOwner();

        return this.unknownPartyStringValueToBoolen(unknownOwner);
    }

    private unknownPartyStringValueToBoolen(value: string): boolean {
        if (value == null || value == undefined || value != this.constantsService.TrueStringValue) {
            return false;
        }

        if (value == this.constantsService.TrueStringValue) {
            return true;
        }
    }

    private hasInvalidModels(ngModels: NgModel[]): boolean {
        return ngModels.filter((model: NgModel) => {
            if (!model) { // if its undefined or null, exit.
                return false;
            }

            if (model.invalid) {
                model.control.markAsDirty();
                model.control.markAsTouched();
                return true;
            }

            return false;
        }).length > 0; // return the count of any invalid models
    }

    private expandFixedProperty(models: LossParticipantModel[] | VehicleInfoModel[]): void {

        if (this.vm.ClaimType.ClaimType !== this.constantsService.ClaimTypeCodeFixedProperty) {
            return;
        }

        let model = models[0];
        // If vehicle is expanded, expand fixed property too.
        if ('PropertyId' in model) {
            this.vm.PropertyInfoModels.map(x => x.DisplayMode = DisplayModeEnum.Expanded);
        }
    }

    private closeFixedProperty(models: LossParticipantModel[] | VehicleInfoModel[]): void {

        if (this.vm.ClaimType.ClaimType !== this.constantsService.ClaimTypeCodeFixedProperty) {
            return;
        }

        let model = models[0];
        if ('PropertyId' in model) {

            if (this.vm.PropertyInfoModels[0].DisplayMode == DisplayModeEnum.Edit) {
                this.revertChanges({ ...this.vm.PropertyInfoModels[0] });
            }

            this.vm.PropertyInfoModels.map(x => x.DisplayMode = DisplayModeEnum.ReadOnly);
        }
    }

    private canDisplayLossDescriptionBox()
    {
        if (this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeRearEndAccidentAF ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeRearEndAccidentNF ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeTireBlowout ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeRoadHazard ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeRoadHazardNF ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeFallingObject ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeAnimalCollision ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeEarthquake ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeHail ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeFloodRisingWater ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeWindTornado ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeSnowIce ||
            this.vm.ClaimType.ClaimType === this.constantsService.ClaimTypeCodeLightning ||
            this.constantsService.ClaimTypeMechanicalBreakdown.indexOf(this.vm.ClaimType.ClaimType) > -1)
        {
            return false;
        }
        else
        {
            return true;
        }
    }
}