export enum BrandEnum {
    DirectAuto = "directauto",
    NationalGeneral = "nationalgeneral",
    Farmers = "farmersunioninsurance",
    GoodSam = "goodsamrvinsurance",
    MC360 = "myclaims360",
    AIE = "aie-ny",
    MVIC = "mvic-aie",
    NJSI = "njsi",
    EncompassInsurance = "encompassinsurance",
    Esurance = "esurance",
    NoBrand = "nobrand",
    SafeAuto = "safeauto",
    Custom360 = "custom360",
}