import { Component } from '@angular/core';

import { ErrorHandlerService } from '../services/error/errorHandler.service';
import { BaseComponent } from '../base/base.component';
import { StyleManagerService } from '../services';

@Component({
    selector: 'error',
    templateUrl: '../views/errorDisplay.component.html'
})
export class ErrorDisplayComponent extends BaseComponent {

    public errors: string[] = [];
    public showErrors: boolean = false;
    public contactNumber: string;

    constructor(private errorHandler: ErrorHandlerService, private styleManager: StyleManagerService) {
        super();
    }

    ngOnInit(): void {
        this.errorHandler.getShowErrors().subscribe((show) => {
            this.errors = this.errorHandler.getErrors();
            this.showErrors = show;
        });

        this.styleManager.contactPhoneNumber.subscribe(contactPhoneNumber => {
            this.contactNumber = contactPhoneNumber;
        })
    }
}