import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import * as moment from 'moment';

import { BaseContactFormComponent } from '@Common/base/baseContactForm.component';
import { ParticipantApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { AddressValidationService, ConstantsService } from '@Common/services/';
import {
    LossParticipantModel, AddressModel,
    ContactInfoModel, PhoneModel, EmailModel, ParticipantClaimDetailsModel, AddressValidationModel, InsuranceInformationModel, ValueDetailModel, ParticipantModel
} from '@ClaimsModels/index';


@Component({
    selector: 'fixedPropertyOwner',
    templateUrl: '../../views/participant/fixedPropertyOwner.component.html',
    providers: [FNOLRoutingService]
})
export class FixedPropertyOwnerComponent extends BaseContactFormComponent {
    constructor(private api: ParticipantApi, private spinner: Ng4LoadingSpinnerService, private addressValidationService: AddressValidationService,
        private fnolRouting: FNOLRoutingService, private fnolAutoService: FNOLAutoService, private constantsService: ConstantsService) {
        super();

        this.vm = new LossParticipantModel();
        this.vm.Address = new AddressModel();
        this.vm.ContactInfo = new ContactInfoModel();
        this.vm.ParticipantClaimDetails = new ParticipantClaimDetailsModel();
        this.vm.InsuranceInformation = new InsuranceInformationModel();

        this.addressValidationService.initialize(this.continuePastAddressValidation.bind(this), this.updateAddress.bind(this), this.isValidAddress.bind(this));
    }

    public vm: LossParticipantModel = new LossParticipantModel();
    public unknown: ParticipantModel = new ParticipantModel();
    public phone: string = this.constantsService.EmptyString;
    public email: string = this.constantsService.EmptyString;
    public dateOfBirth: string = this.constantsService.EmptyString;
    public propertyDescription: string = null;

    private propertyId: number = 0;
    private lossParticipantId: number = 0;


    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public addUnknown(): void {

        this.unknown.IsSelected = !this.unknown.IsSelected;
        this.fnolAutoService.setUnknownFixedPropertyOwner(this.unknown.IsSelected.toString());
    }

    ngOnInit(): void {
        this.spinner.show();

        this.lossParticipantId = this.fnolRouting.getRoute().snapshot.params.id || 0;

        this.propertyId = this.fnolRouting.getRoute().snapshot.params.propertyId || 0;

        this.checkUnknownOwner();

        this.api.getFixedPropertyOwnerModel(this.lossParticipantId).then(response => {
            this.spinner.hide();
            this.vm = response.LossParticipant;
            this.propertyDescription = response.PropertyDescription !== null && response.PropertyDescription !== this.constantsService.EmptyString
                ? response.PropertyDescription
                : null;

            if (this.vm.DateOfBirth != null) {
                this.dateOfBirth = moment(this.vm.DateOfBirth).format("MM/DD/YYYY");
            }

            if (this.vm.ContactInfo != null &&
                this.vm.ContactInfo.Emails != null &&
                this.vm.ContactInfo.Emails.length > 0) {
                this.email = this.vm.ContactInfo.Emails[0].EmailAddress;
            }
        });
    }

    public canAddPhone(): boolean {
        if (this.vm.ContactInfo.Phones.length >= 3) {
            return false;
        }
        return true; 3;
    }

    public addPhone(): void {
        let newPhone = new PhoneModel();
        newPhone.PhoneNumber = null;
        newPhone.PhoneType = null;
        newPhone.IsActive = true;
        this.vm.ContactInfo.Phones.push(newPhone);
    }

    public availablePhoneTypes(phone: PhoneModel): ValueDetailModel[] {
        let activePhones = this.vm.ContactInfo.Phones.filter(x => x.IsActive);

        let result = [];

        this.vm.PhoneTypes.forEach(x => {
            if (!activePhones.find(y => y.PhoneType === x.DisplayCode) || phone.PhoneType === x.DisplayCode) {
                result.push(x);
            }
        });

        return result;
    }

    public removePhone(index: number): void {
        this.vm.ContactInfo.Phones.splice(index, 1);
        //phone.IsActive = false;
    }

    public track(index: number) {
        return index;
    }

    public updateAddress(address: AddressModel): void {
        this.vm.Address = address;
    }

    submit(form: NgForm): void {

        if (this.IsNullOrEmptyString(this.vm.FirstName) && this.IsNullOrEmptyString(this.vm.LastName)) {

            if (!this.unknown.IsSelected) {
                this.addUnknown();
            }

            this.fnolRouting.next();
            return;
        }

        if (this.validate(form)) {
            this.vm.ParticipantClaimDetails.IsOnPolicy = false;
            this.vm.ParticipantClaimDetails.ParticipantRole = this.constantsService.Claimant;
            this.vm.FixedPropertyId = this.propertyId;

            var validationAddress: AddressValidationModel = new AddressValidationModel();
            validationAddress.AddressToValidate = this.vm.Address;
            this.spinner.show();

            this.addressValidationService.checkAddress(validationAddress).then(addressValidationResponse => {
                this.spinner.hide();

                let addresses = {
                    originalAddress: this.vm.Address,
                    suggestedAddress: addressValidationResponse.AddressToValidate
                };

                this.addressValidationService.validateAddress(addressValidationResponse, addresses, form);
            });
        }


    }

    private continuePastAddressValidation(): void {
        this.spinner.show();
        this.mapPhoneAndEmail();

        if (this.unknown.IsSelected) {
            this.addUnknown();
        }

        if (this.dateOfBirth !== null && this.dateOfBirth !== this.constantsService.EmptyString) {
            this.vm.DateOfBirth = this.convertToUTCDate(this.dateOfBirth);
        } else {
            this.vm.DateOfBirth = null;
        }

        this.api.saveLossParticipantModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {

                this.replaceUrl(response.LossParticipantId);

                this.fnolRouting.next();
            }
        });
    }

    private mapPhoneAndEmail() {
        let emailType: string = null;

        if (this.vm.ContactInfo.Emails != null && this.vm.ContactInfo.Emails.length > 0) {
            emailType = this.vm.ContactInfo.Emails[0].EmailType;
        }

        this.vm.ContactInfo.Emails = [];

        if (this.email !== null && this.email !== this.constantsService.EmptyString) {
            let emailModel = new EmailModel();
            emailModel.EmailAddress = this.email;
            emailModel.EmailType = emailType != null ? emailType : this.constantsService.EmailTypePER;
            this.vm.ContactInfo.Emails.push(emailModel);
        }
    }

    private replaceUrl(participantId: number): void {
        if (this.lossParticipantId == 0) {
            let newUrl = `fnolAuto/property/${this.propertyId}/propertyOwner/${participantId}`;

            this.fnolRouting.replaceCurrentState(newUrl);
        }
    }

    public checkUnknownOwner(): void {
        let unknownOwner: string = this.fnolAutoService.getUnknownFixedPropertyOwner();

        if (unknownOwner == null || unknownOwner == undefined || unknownOwner != this.constantsService.TrueStringValue) {
            this.unknown.IsSelected = false;
        }

        if (unknownOwner == this.constantsService.TrueStringValue) {
            this.unknown.IsSelected = true;
        }
    }

}